import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Sidebar from './components/sidebar/Sidebar'
import JavascriptDashboard from './components/JavascriptDashboard'
import CssDashboard from './components/CssDashboard'

export default function Dashboard() {
    return (
        <div className='dashboard'>
            <Sidebar />
            <Switch>
                <Route exact path='/javascript'>
                    <JavascriptDashboard />
                </Route>
                <Route exact path='/css'>
                    <CssDashboard />
                </Route>

                <Route>
                    {/* Redirects to dashboard if the path doesnt match */}
                    <Redirect to='/javascript' />
                </Route>
            </Switch>
        </div>
    )
}
