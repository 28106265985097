import React, { useRef } from 'react'
import { Typography } from '@material-ui/core'

import Card from './Card'
import Footer from '../footer/Footer'

export default function JavascriptRoadmap() {
    const chapter1Ref = useRef(null)
    const chapter2Ref = useRef(null)
    const chapter3Ref = useRef(null)
    const chapter4Ref = useRef(null)
    const chapter5Ref = useRef(null)

    const chapters = [
        { number: 1, title: 'Basic introduction', parts: 3 },
        { number: 2, title: 'Concepts', parts: 10 },
        { number: 3, title: 'React', parts: 6 },
        { number: 4, title: 'Projects', parts: 2 },
        { number: 5, title: 'Firebase', parts: 7 },
    ]

    /**
     * @param {number} chapter - the chapter you want to scroll to
     */
    const scrollIntoView = (chapter) => {
        if (chapter === 1) chapter1Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 2) chapter2Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 3) chapter3Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 4) chapter4Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 5) chapter5Ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='roadmap'>
            <div className='content'>
                <div className='legend'>
                    <Typography
                        variant='h3'
                        component='span'
                        className='bold color-green'
                        paragraph
                    >
                        JavaScript
                        <Typography variant='h3' component='h3' className='bold' color='primary'>
                            Roadmap
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle2' color='secondary'>
                        Chapters
                    </Typography>
                    <div className='chapters-wrapper'>
                        {chapters.map((number, index) => {
                            return (
                                <div
                                    className='chapter'
                                    key={index}
                                    onClick={() => scrollIntoView(number.number)}
                                >
                                    <div className='number'>
                                        <Typography
                                            variant='h5'
                                            color='primary'
                                            className='bold'
                                            align='center'
                                        >
                                            0{number.number}
                                        </Typography>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='text'>
                                        <Typography
                                            variant='subtitle1'
                                            color='primary'
                                            display='block'
                                        >
                                            {number.title}
                                        </Typography>
                                        <Typography variant='caption' color='secondary'>
                                            {number.parts} parts
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Footer />
                </div>
                <div className='cards-wrapper'>
                    <Card
                        ref={chapter1Ref}
                        title=' JavaScript beginner course'
                        subtitle='Learn the fundamentals of the JavaScript programing language'
                        chapter='1'
                        chips={['60 min', 'Video']}
                        video='W6NZfCO5SIk'
                    />
                    <Card
                        title='Learn JS in 5 minutes'
                        subtitle='Follow this super quick tutorial that oversees the JavaScript language'
                        chapter='1'
                        chips={['5 min', 'Video']}
                        video='c-I5S_zTwAc'
                    />
                    <Card
                        title='Learn a few JS tips and tricks'
                        subtitle='Get to know some good best practices and unique tricks the JavaScript language provides'
                        chapter='1'
                        chips={['15 min', 'Video']}
                        video='Mus_vwhTCq0'
                    />
                    {/* Chapter 2 */}
                    <Card
                        ref={chapter2Ref}
                        title='Var, Let and Const'
                        subtitle='Understand the difference of the different ways a variable can be declared'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='9WIJQDvt4Us'
                    />
                    <Card
                        title='Objects'
                        subtitle='Objects are key building blocks for the language, knowing everything about them will help you a lot.'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='napDjGFjHR0'
                    />
                    <Card
                        title='Arrays'
                        subtitle='Quick look at the Javascript Arrays'
                        chapter='2'
                        chips={['5 min', 'Video', 'Concept']}
                        video='oigfaZ5ApsM'
                    />
                    <Card
                        title='Loops'
                        subtitle='Get a deeper understanding for loops in Javascript'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='x7Xzvm0iLCI'
                    />
                    <Card
                        title=' Arrow Functions'
                        subtitle='Arrow functions are a cleaner way of declaring functions and comes with some other functionality as well'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='h33Srr5J9nY'
                    />
                    <Card
                        title='Array Methods'
                        subtitle='Array methods are super useful for a lot of different reasons and can really save you some time if you know them.'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='R8rmfD9Y5-c'
                    />
                    <Card
                        title='Async/Await (Video)'
                        subtitle='Async/Await is used with fetching data and is a must for any application you want to scale'
                        chapter='2'
                        chips={['15 min', 'Video', 'Concept']}
                        video='vn3tm0quoqE'
                    />
                    <Card
                        title='Async/Await (Article)'
                        subtitle='Async/Await is used with fetching data and is a must for any application you want to scale'
                        chapter='2'
                        chips={['20 min', 'Article', 'Concept']}
                        link='https://medium.com/technofunnel/javascript-async-await-c83b15950a71'
                        img='https://miro.medium.com/max/2000/1*b3qxB8ELI-kyQKztCsLwEQ.png'
                    />
                    <Card
                        title='Closures'
                        chapter='2'
                        chips={['5 min', 'Video', 'Concept']}
                        video='vKJpN5FAeF4'
                    />
                    <Card
                        title='"this" in Javascript'
                        subtitle='The "this" keyword in Javascript will be super useful to understand for writing more complex systems '
                        chapter='2'
                        chips={['5 min', 'Video', 'Concept']}
                        video='YOlr79NaAtQ'
                    />
                    {/* Chapter 3 */}
                    <Card
                        ref={chapter3Ref}
                        title='Get started with React'
                        subtitle='Start of by reading the docs for the framework, follow the examples and try it out for your self'
                        chapter='3'
                        chips={['Documentation', 'React']}
                        link='https://reactjs.org/'
                        img='https://ms314006.github.io/static/b7a8f321b0bbc07ca9b9d22a7a505ed5/97b31/React.jpg'
                    />
                    <Card
                        title='React in 2 minutes'
                        subtitle='2 minute overlook of the framework'
                        chapter='3'
                        chips={['2 min', 'Video', 'React']}
                        video='Tn6-PIqc4UM'
                    />
                    <Card
                        title='React intro video'
                        subtitle="If reading documentation isn't your thing you can also watch this
                        YouTube video that will help to get started with the amazing
                        framework that is react"
                        chapter='3'
                        chips={['110 min', 'Video', 'React']}
                        video='w7ejDZ8SWv8'
                    />
                    <Card
                        title='Todo App'
                        subtitle='Apply your React knowledge to build something particle'
                        chapter='3'
                        chips={['90 min', 'Video', 'React', 'Project']}
                        video='pCA4qpQDZD8'
                    />
                    <Card
                        title='Hooks'
                        subtitle='The react hooks are essential for the react workflow. It might seem scary at first but will get more intuitive the more you use them  '
                        chapter='3'
                        chips={['110 min', 'Video', 'React', 'Concept']}
                        video='f687hBjwFcM'
                    />
                    <Card
                        title='React Projects'
                        subtitle='Get your hands dirty by following along this'
                        chapter='3'
                        chips={['500 min', 'Video', 'React', 'Project']}
                        video='a_7Z7C_JCyo'
                    />
                    {/* Chapter 4 */}
                    <Card
                        ref={chapter4Ref}
                        title='Build a sorting algorithm visualizer'
                        subtitle='Start of by reading the docs for the framework, follow the examples and try it out for your self'
                        chapter='4'
                        chips={['100 min', 'Video', 'Project']}
                        video='pFXYym4Wbkc'
                    />
                    <Card
                        title='Build a pathfinder algorithm visualizer'
                        subtitle='Start of by reading the docs for the framework, follow the examples and try it out for your self'
                        chapter='4'
                        chips={['120 min', 'Video', 'Project']}
                        video='msttfIHHkak'
                    />
                    {/* Chapter 5 */}
                    <Card
                        ref={chapter5Ref}
                        title='Get started with Firebase'
                        subtitle='Firebase is an amazing tool that can take care of the majority of your backend, it pairs super well with react'
                        chapter='5'
                        link='https://firebase.google.com/docs'
                        chips={['Documentation', 'Firebase']}
                        img='https://firebase.google.com/images/social.png'
                    />
                    <Card
                        title='Get to know Firestore'
                        subtitle='Get to know Firestore in this video series from google.'
                        chapter='5'
                        chips={['15 min', 'Video', 'Firebase']}
                        video='v_hR4K4auoQ'
                    />
                    <Card
                        title="Firebase Beginner's guid "
                        subtitle="A quick beginner's guide to Firebase"
                        chapter='5'
                        chips={['20 min', 'Video', 'Firebase']}
                        video='9kRgVxULbag'
                    />
                    <Card
                        title='Firebase, back to the basics'
                        subtitle='Cover even more of the basics for firebase'
                        chapter='5'
                        chips={['25 min', 'Video', 'Firebase']}
                        video='q5J5ho7YUhA'
                    />

                    <Card
                        title='Firebase Authenticate '
                        subtitle='Follow this video to learn some best practices for firebase as well as how to use the authentication'
                        chapter='5'
                        chips={['80 min', 'Video', 'React', 'Firebase']}
                        video='PKwu15ldZ7k'
                    />
                    <Card
                        title='100 Tips and tricks for Firebase '
                        subtitle='Follow this video to learn some best practices for firebase aswell as how to use the authentication'
                        chapter='5'
                        chips={['25 min', 'Video', 'Firebase']}
                        video='iWEgpdVSZyg'
                    />
                    <Card
                        title='Chat app'
                        subtitle='Learn some best practices for firebase aswell as how to use the authentication'
                        chapter='5'
                        chips={['10 min', 'Video', 'React', 'Project', 'Firebase']}
                        video='zQyrwxMPm88'
                    />
                </div>
            </div>
        </div>
    )
}
