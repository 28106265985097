import { createMuiTheme } from '@material-ui/core/styles'

const lightBg = '#757575'
// const white = '#fff'
// const white = '#f1f7ed'
const white = '#F2ECFF'
// const white = '#FFEBE7'
const colorPurple = '#d454fa'
const darkPurple = '#ba46dc'
const darkBlue = '#0d96ff'
const lightBlue = ' #35acfe'

const primary = { light: white, main: white, dark: white, contrastText: lightBg }
const secondary = { light: lightBg, main: lightBg, dark: lightBg }
const purple = { light: colorPurple, main: colorPurple, dark: darkPurple }
const blue = { light: lightBlue, main: darkBlue, dark: darkBlue }

export const mainTheme = createMuiTheme({
    purple: purple,
    palette: {
        primary: primary,
        secondary: secondary,
        text: { secondary: blue },
    },
})
