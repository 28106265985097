import React from 'react'
import JavascriptRoadmap from './roadmap/JavascriptRoadmap'

export default function JavascriptDashboard() {
    return (
        <div className='language-content'>
            <JavascriptRoadmap />
        </div>
    )
}
