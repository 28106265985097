import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { ReactComponent as JS } from '../../../../JS-logo.svg'
import { ReactComponent as CSS } from '../../../../CSS-logo.svg'
import { ReactComponent as Discord } from '../../../../Discord-logo.svg'
import { ReactComponent as Facebook } from '../../../../Facebook-logo.svg'
import { BiRocket } from 'react-icons/bi'

export default function Sidebar() {
    const history = useHistory()
    const location = useLocation()

    const clickedHandler = (link) => {
        history.push(link)
    }

    return (
        <div className='sidebar-wrapper'>
            <img
                id='techover-logo'
                src='/techover-logo-only.png'
                width='40'
                alt='techover'
            />
            <div
                className={`option-container ${
                    location.pathname === '/javascript' ? 'selected' : ''
                }`}
                onClick={() => clickedHandler('javascript')}
            >
                <JS />
            </div>
            <div
                className={`option-container ${
                    location.pathname === '/css' ? 'selected' : ''
                }`}
                onClick={() => clickedHandler('css')}
            >
                <CSS />
            </div>
            <a
                href='https://discord.gg/K9fEs2y94d'
                target='_blank'
                rel='noreferrer'
                className='option-container'
            >
                <Discord />
            </a>
            <a
                href='https://www.facebook.com/groups/1382562668745915'
                target='_blank'
                rel='noreferrer'
                className='option-container'
            >
                <Facebook id='facebook-option' />
            </a>

            <a
                href='https://academy.techover.nu/'
                target='_blank'
                rel='noreferrer'
                className='option-container'
            >
                <BiRocket
                    style={{ width: '50%', height: '50%', color: '#fff' }}
                />
            </a>
        </div>
    )
}
