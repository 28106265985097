import React, { useRef } from 'react'
import { Typography } from '@material-ui/core'
import Card from './Card'
import Footer from '../footer/Footer'

export default function CssRoadmap() {
    const chapter1Ref = useRef(null)
    const chapter2Ref = useRef(null)
    const chapter3Ref = useRef(null)
    const chapter4Ref = useRef(null)

    const chapters = [
        { number: 1, title: 'Basic introduction', parts: 3 },
        { number: 2, title: 'Concepts', parts: 6 },
        { number: 3, title: 'Library', parts: 6 },
        { number: 4, title: 'Resources', parts: 2 },
    ]

    /**
     * @param {number} chapter - the chapter you want to scroll to
     */
    const scrollIntoView = (chapter) => {
        if (chapter === 1) chapter1Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 2) chapter2Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 3) chapter3Ref.current.scrollIntoView({ behavior: 'smooth' })
        if (chapter === 4) chapter4Ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <div className='roadmap'>
            <div className='content'>
                <div className='legend'>
                    <Typography
                        variant='h3'
                        component='span'
                        className='bold color-green'
                        paragraph
                    >
                        CSS
                        <Typography variant='h3' component='h3' className='bold' color='primary'>
                            Roadmap
                        </Typography>
                    </Typography>
                    <Typography variant='subtitle2' color='secondary'>
                        Chapters
                    </Typography>
                    <div className='chapters-wrapper'>
                        {chapters.map((number, index) => {
                            return (
                                <div
                                    className='chapter'
                                    key={index}
                                    onClick={() => scrollIntoView(number.number)}
                                >
                                    <div className='number'>
                                        <Typography
                                            variant='h5'
                                            color='primary'
                                            className='bold'
                                            align='center'
                                        >
                                            0{number.number}
                                        </Typography>
                                    </div>
                                    <div className='divider'></div>
                                    <div className='text'>
                                        <Typography
                                            variant='subtitle1'
                                            color='primary'
                                            display='block'
                                        >
                                            {number.title}
                                        </Typography>
                                        <Typography variant='caption' color='secondary'>
                                            {number.parts} parts
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <Footer />
                </div>
                <div className='cards-wrapper'>
                    <Card
                        ref={chapter1Ref}
                        title='Intro to CSS'
                        subtitle='CSS documentation with hands on examples'
                        chapter='1'
                        chips={['Documentation']}
                        link='https://www.w3schools.com/css/default.asp'
                        img='https://www.hebergementwebs.com/image/98/98d4ab04d0b5f7063a27f86e9a8d16af.jpg/30-fantastiska-gratis-css-layouter-f%C3%B6r-ui-designers-1.jpg'
                    />

                    <Card
                        title='Learn CSS in 20 minutes'
                        subtitle='Get to to know basics of CSS in 20 minutes'
                        chapter='1'
                        chips={['20 min', 'Video']}
                        video='1PnVor36_40'
                    />

                    <Card
                        title='Learn Every CSS Selector In 20 Minutes'
                        subtitle='CSS selectors are an impornt building block for styling any website. Knowing them will get you a long way.'
                        chapter='1'
                        chips={['20 min', 'Video']}
                        video='l1mER1bV0N0'
                    />

                    {/* Chapter 2 */}
                    <Card
                        ref={chapter2Ref}
                        title='10 CSS tips and tricks'
                        subtitle='10 CSS tips and tricks to further enhance your knowledge'
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='Qhaz36TZG5Y'
                    />
                    <Card
                        title='More CSS tips'
                        subtitle='Some more tips to improve your CSS knowledge'
                        chapter='2'
                        chips={['5 min', 'Video', 'Concept']}
                        video='JSURzPQnkl0'
                    />
                    <Card
                        title='CSS Grid'
                        subtitle='CSS grid is a super powerful way of creating layouts'
                        chapter='2'
                        chips={['15 min', 'Video', 'Concept']}
                        video='705XCEruZFs'
                    />
                    <Card
                        title='Flexbox'
                        subtitle='Flexbox lets you create one-dimensional layouts in both a easy but also a very responsive way'
                        chapter='2'
                        chips={['2 min', 'Video', 'Concept']}
                        video='K74l26pE4YA'
                    />
                    <Card
                        title='CSS Media query'
                        subtitle="Media query's let you style the websites depending on viewport-size, orientation, device and much more. This is super useful for making responsive websites"
                        chapter='2'
                        chips={['10 min', 'Video', 'Concept']}
                        video='yU7jJ3NbPdA'
                    />

                    <Card
                        title='CSS combinator'
                        subtitle='CSS combinators gives you a finer control of how your elements should be styled'
                        chapter='2'
                        chips={['15 min', 'Video', 'Concept']}
                        video='B-eM50GBB1Y'
                    />
                    <Card
                        title='CSS Units'
                        subtitle='CSS units are the '
                        chapter='2'
                        chips={['15 min', 'Video', 'Concept']}
                        video='N5wpD9Ov_To'
                    />

                    <Card
                        title='Underused CSS properties'
                        subtitle='In CSS there are a lot of different properties, values, and selectors that can help you in specific scenarios'
                        chapter='2'
                        chips={['15 min', 'Video', 'Concept']}
                        video='qqaSJRNP8lQ'
                    />
                    <Card
                        title='CSS Navigation'
                        subtitle='Learn some best practices for a building responsive website'
                        chapter='2'
                        chips={['15 min', 'Video', 'Project']}
                        video='biOMz4puGt8'
                    />
                    {/* Chapter 3 */}
                    <Card
                        ref={chapter3Ref}
                        title='Get started with SCSS'
                        subtitle='SCSS is CSS with a lot of extra features. SCSS also fits right into the React workspace'
                        chapter='3'
                        chips={['Documentation']}
                        link='https://sass-lang.com/'
                        img='https://blog.alexdevero.com/wp-content/uploads/2015/03/sass-logo.jpg'
                    />
                    <Card
                        title='SCSS in 2 minutes'
                        subtitle='Quick overlook over SCSS.'
                        chapter='3'
                        chips={['2 min', 'Video']}
                        video='akDIJa0AP5c'
                    />
                    <Card
                        title='Compile SCSS'
                        subtitle='Learn how to compile your SCSS.'
                        chapter='3'
                        chips={['20 min', 'Video']}
                        video='wYWf2m_yzBQ'
                    />
                    <Card
                        title='SCSS in 20 minutes'
                        subtitle='Quickly get to know SCSS '
                        chapter='3'
                        chips={['20 min', 'Video']}
                        video='Zz6eOVaaelI'
                    />
                    <Card
                        title='Bootstrap'
                        subtitle='Bootstrap is styling library that helps you style your site with a unified look.'
                        chapter='3'
                        chips={['Documentation']}
                        link='https://getbootstrap.com/'
                        img='https://www.pngkey.com/png/detail/142-1424654_we-help-you-to-solve-any-issues-or.png'
                    />
                    <Card
                        title='Bootsrap Crash Course'
                        subtitle='Learn the fundementals of Bootsrap'
                        chapter='3'
                        chips={['80 min', 'Video']}
                        video='4sosXZsdy-s'
                    />
                    <Card
                        title='Material UI'
                        subtitle='Material is styling library that helps you style your site with a unified look.'
                        chapter='3'
                        chips={['Documentation']}
                        link='https://material-ui.com/'
                        img='https://pro.guslyon.fr/static/material-ui-4a66e3fd52c7ec9469f4684b68aca251.png'
                    />
                    <Card
                        title='Bootsrap Crash Course'
                        subtitle='Follow this beginner series to become proficient in Material UI'
                        chapter='3'
                        chips={['10 min', 'Video']}
                        video='0KEpWHtG10M'
                    />
                    {/* Chapter 4 */}
                    <Card
                        ref={chapter4Ref}
                        title='Frontend Mentor'
                        subtitle='Frontend Mentor is a wonderful website that gives you website mockups that you can follow to sharpen your skills without needing to worry about designing a website'
                        chapter='4'
                        chips={['Resource']}
                        link='https://www.frontendmentor.io/'
                        img='https://res.cloudinary.com/dz209s6jk/image/upload/v1585172856/Meta/meta-challenges.png'
                    />
                    <Card
                        title='CSS Battles'
                        subtitle='CSS Battles is a code golfing game where you can learn a lot of unique things about CSS whilst having fun.'
                        chapter='4'
                        chips={['Resource']}
                        link='https://cssbattle.dev/'
                        img='https://cdn-media-1.freecodecamp.org/images/1*yDgSJrVPPH70Jdh6KUyokA.png'
                    />
                    <Card
                        title='CSS Podcast'
                        subtitle='CSS Podcast is a podcast where the topic has to do with everything in CSS, you never know what new trick you might learn.'
                        chapter='4'
                        chips={['Resource']}
                        link='https://thecsspodcast.libsyn.com/'
                        img='https://web.dev/images/podcasts/css-podcast.jpg'
                    />
                </div>
            </div>
        </div>
    )
}
