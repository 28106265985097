import React from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { mainTheme } from './material-themes'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Dashboard from './components/dashboard/Dashboard'
function App() {
    // console.log(
    //     '        _____  _____  _   _     _       _  _____  ___       _   _  ___    ___    ___' +
    //         '\n' +
    //         "/'\\_/`\\(  _  )(_   _)( ) ( )   ( )  _  ( )(  _  )(  _`\\    ( ) ( )(  _`\\ |  _`\\ (  _`\\ " +
    //         '\n' +
    //         '|     || (_) |  | |  | | | |   | | ( ) | || (_) || (_(_)   | |_| || (_(_)| (_) )| (_(_)' +
    //         '\n' +
    //         '| (_) ||  _  |  | |  | | | |   | | | | | ||  _  |`\\__ \\    |  _  ||  _)_ | ,  / |  _)_ ' +
    //         '\n' +
    //         '| | | || | | |  | |  | (_) |   | (_/ \\_) || | | |( )_) |   | | | || (_( )| |\\ \\ | (_( )' +
    //         '\n' +
    //         "(_) (_)(_) (_)  (_)  (_____)   `\\___x___/'(_) (_)`\\____)   (_) (_)(____/'(_) (_)(____/'"
    // )

    console.log(
        `
     ___           ___           ___           ___                    ___           ___           ___                    ___           ___           ___           ___     
    /\\__\\         /\\  \\         /\\  \\         /\\__\\                  /\\__\\         /\\  \\         /\\  \\                  /\\__\\         /\\  \\         /\\  \\         /\\  \\    
   /::|  |       /::\\  \\        \\:\\  \\       /:/  /                 /:/ _/_       /::\\  \\       /::\\  \\                /:/  /        /::\\  \\       /::\\  \\       /::\\  \\   
  /:|:|  |      /:/\\:\\  \\        \\:\\  \\     /:/  /                 /:/ /\\__\\     /:/\\:\\  \\     /:/\\ \\  \\              /:/__/        /:/\\:\\  \\     /:/\\:\\  \\     /:/\\:\\  \\  
 /:/|:|__|__   /::\\~\\:\\  \\       /::\\  \\   /:/  /  ___            /:/ /:/ _/_   /::\\~\\:\\  \\   _\\:\\~\\ \\  \\            /::\\  \\ ___   /::\\~\\:\\  \\   /::\\~\\:\\  \\   /::\\~\\:\\  \\ 
/:/ |::::\\__\\ /:/\\:\\ \\:\\__\\     /:/\\:\\__\\ /:/__/  /\\__\\          /:/_/:/ /\\__\\ /:/\\:\\ \\:\\__\\ /\\ \\:\\ \\ \\__\\          /:/\\:\\  /\\__\\ /:/\\:\\ \\:\\__\\ /:/\\:\\ \\:\\__\\ /:/\\:\\ \\:\\__\\
\\/__/~~/:/  / \\/__\\:\\/:/  /    /:/  \\/__/ \\:\\  \\ /:/  /          \\:\\/:/ /:/  / \\/__\\:\\/:/  / \\:\\ \\:\\ \\/__/          \\/__\\:\\/:/  / \\:\\~\\:\\ \\/__/ \\/_|::\\/:/  / \\:\\~\\:\\ \\/__/
      /:/  /       \\::/  /    /:/  /       \\:\\  /:/  /            \\::/_/:/  /       \\::/  /   \\:\\ \\:\\__\\                 \\::/  /   \\:\\ \\:\\__\\      |:|::/  /   \\:\\ \\:\\__\\  
     /:/  /        /:/  /     \\/__/         \\:\\/:/  /              \\:\\/:/  /        /:/  /     \\:\\/:/  /                 /:/  /     \\:\\ \\/__/      |:|\\/__/     \\:\\ \\/__/  
    /:/  /        /:/  /                     \\::/  /                \\::/  /        /:/  /       \\::/  /                 /:/  /       \\:\\__\\        |:|  |        \\:\\__\\    
    \\/__/         \\/__/                       \\/__/                  \\/__/         \\/__/         \\/__/                  \\/__/         \\/__/         \\|__|         \\/__/    
    `
    )

    return (
        <ThemeProvider theme={mainTheme}>
            <Router>
                <Switch>
                    <Route exact path='/*' component={Dashboard} />

                    {/* 404 page  */}
                    <Route path='/'>
                        <div>404 not found</div>
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App
