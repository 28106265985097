import React from 'react'

export default function Footer() {
    return (
        <div className='footer'>
            <p>
                <img src='/techover-footer-logo.png' width='180' alt='techover' />
            </p>
            <br />
            <p>© Copyright 2021 - All Rights Reserved, MMR Solutions AB </p>
        </div>
    )
}
