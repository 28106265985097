import React from 'react'
import { Typography, Chip } from '@material-ui/core'

/**
 * Roadmap Card
 * @param {string} title
 * @param {string} subtitle
 * @param {number} chapter
 * @param {Array(string)} chips - an array of labels for the chips
 * @param {string} video - the unique video link to a video, so just the last 8 or so characters unique to that video
 * @param {string} img - img link
 * @param {string} link - a link to the url for the click
 * @param {useRef} ref - a ref to a chapter
 */

const Card = React.forwardRef(({ title, subtitle, chapter, chips, video, img, link }, ref) => {
    return (
        <a
            className='card'
            ref={ref}
            href={link ? link : `https://www.youtube.com/watch?v=${video}`}
            target='blank'
        >
            {/* <a href={`https://www.youtube.com/watch?v=${video}`}> */}
            <div className='chapter-display'>0{chapter}</div>
            {img ? (
                <div className='img-wrapper' style={{ backgroundImage: `url(${img})` }}></div>
            ) : (
                <div className='iframe-wrapper'>
                    <iframe
                        // loading='lazy'
                        src={`https://www.youtube.com/embed/${video}`}
                        title='YouTube video player'
                        frameBorder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                    ></iframe>
                </div>
            )}
            <div className='text'>
                <Typography variant='h6' color='primary'>
                    {title}
                </Typography>
                <Typography variant='caption' color='secondary'>
                    {subtitle}
                </Typography>
                <div className='chips-wrapper'>
                    {chips?.map((chip, index) => (
                        <Chip label={chip} key={index} className={chip} />
                    ))}
                </div>
            </div>
            {/* </a> */}
        </a>
    )
})

export default Card
