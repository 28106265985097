import React from 'react'
import CssRoadmap from './roadmap/CssRoadmap'

export default function CssDashboard() {
    return (
        <div className='language-content'>
            <CssRoadmap />
        </div>
    )
}
